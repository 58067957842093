import React, { FC, useCallback } from "react";
import Link from "next/link";
import styled from "styled-components";
import SsButton from "components/front/layout/SsButton";
import { useRouter } from "next/router";
import { buildUrlParam } from "lib/util";

export const FrontSnsLoginForm: FC = () => {
  const onTwitterLoginClick = useOauthLoginUrl("/oauth/twitter/login");
  const onGoogleLoginClick = useOauthLoginUrl("/oauth/google/login");
  return (
    <div>
      <LoginSnsList>
        <StyledSsButton fill="#55abed" color="#FFF" imageIcon="/img/login_tw.svg" onClick={onTwitterLoginClick}>
          Twitterでログインする
        </StyledSsButton>
        <StyledSsButton borderColor="#dadce0" imageIcon="/img/login_g.svg" onClick={onGoogleLoginClick}>
          Googleでログインする
        </StyledSsButton>
      </LoginSnsList>
      <TermConfirmation>
        <Link href={"/term"}>
          <a target={"_blank"}>利用規約</a>
        </Link>
        に同意の上でログインしてください。
      </TermConfirmation>
    </div>
  );
};

const useOauthLoginUrl = (snsLoginPath: string): () => void => {
  const router = useRouter();
  return useCallback(() => {
    const originalPath = router.query["original"] as string | undefined;
    const queryParams = originalPath ? buildUrlParam({ restart_url: encodeURIComponent(originalPath) }) : "";
    window.location.assign(`${process.env.API_BASE_URL}${snsLoginPath}?${queryParams}`);
  }, [router.query])
}

const StyledSsButton = styled(SsButton)`
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
`;

const TermConfirmation = styled.div`
  font-size: 12px;
  margin: 10px 0;
  padding: 10px;
  text-align: center;
  background-color: #e5e5e5;

  & a {
    text-decoration: underline;
  }

  @media screen and (max-width: 960px) {
    margin: 10px;
  }
`;

const LoginSnsList = styled.div`
  @media screen and (max-width: 960px) {
    width: 100%;
    padding: 0 10px;
  }
`;
