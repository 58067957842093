import { Field, FieldProps } from "formik";
import { makeStyles } from "@material-ui/core";

export interface RadioNames {
  label: string;
  value: string;
}

export type Props = {
  label: string;
  name: string;
  radioNames: RadioNames[];
}

const useStyles = makeStyles(() => ({
  field: {
    display: 'inline-flex'
  },
  radio: {
    margin: 'auto',
    height: 'min-content',
  }
}));

export const FrontRadioButton: React.FC<Props> = ({ label, name, radioNames }: Props) => {
  const classes = useStyles();
  return (
    <div className="form--item">
      <Field label={label} name={name}>
        {({ field, form }: FieldProps<string>): React.ReactNode => {
          return (
            <>
              <p className="form--item_name form--item_gender">{label}</p>
              <div className="form--item_radio">
                {radioNames.map((radioName, i) => {
                  return (
                    <div className="form--item_radio--item" key={i}>
                      <input
                        id={`radio_${i}`}
                        className={classes.radio}
                        type="radio"
                        name={field.name}
                        value={radioName.value}
                        checked={field.value === radioName.value}
                        onChange={form.handleChange}
                      />
                      <div className="form--item_radio--item_check"></div>
                      <label htmlFor={`radio_${i}`}><span>{radioName.label}</span></label>
                    </div>
                  )
                })}
              </div>
            </>
          )
        }}
      </Field>
    </div>
  )
}
