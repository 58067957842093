import React, { HTMLAttributes } from "react";
import clsx from "clsx";
import styled from "styled-components";

type Props = HTMLAttributes<HTMLDivElement> & {
  text: string;
  type: "button" | "submit";
  disabled?: boolean;
};

// TODO: OnClickの挙動の引数作ってない。テンプレートの「ログイン系のボタン」って言われてるものをパクってるよ
export const RegisterButton2: React.FC<Props> = (props: Props) => (
  <div {...props} className={clsx("form_submit", props.className)}>
    <div className="link link--submit">
      <div className="link--submit--item">
        <div className="link--submit--item_bg" />
      </div>
      <div className="link--submit--item">
        <div className="link--submit--item_bg" />
      </div>
      <div className="link--submit--item">
        <div className="link--submit--item_bg" />
      </div>
      <div className="link--submit--item">
        <div className="link--submit--item_bg" />
      </div>
      <div className="link--submit--item">
        <div className="link--submit--item_bg" />
      </div>
      <div className="link--submit--item link_text">
        <div className="link--submit--item_bg" />
        <Input value={props.text} type={props.type} disabled={props.disabled} />
      </div>
    </div>
  </div>
);

const Input = styled.input`
  color: black;
`;
