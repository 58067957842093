import React, { FC, HTMLAttributes } from "react";
import styled, { css } from "styled-components";

const SsButton: FC<
  HTMLAttributes<HTMLElement> & { href?: string; fill?: string; color?: string; borderColor?: string; imageIcon?: string }
> = ({ href, fill = "transparent", color = "#000", borderColor, imageIcon, children, ...props }) => {
  return href ? (
    <LinkRoot {...props} href={href} $fill={fill}>
      <Background fill={fill} borderColor={borderColor} />
      <Content $color={color} $imageIcon={imageIcon}>
        {children}
      </Content>
    </LinkRoot>
  ) : (
    <ButtonRoot {...props} $fill={fill}>
      <Background fill={fill} borderColor={borderColor} />
      <Content $color={color} $imageIcon={imageIcon}>
        {children}
      </Content>
    </ButtonRoot>
  );
};
export default SsButton;

const Background: FC<{ fill: string; borderColor?: string }> = ({ fill, borderColor }) => (
  <BgRoot $fill={fill} $borderColor={borderColor}>
    <RightRect $fill={fill} $borderColor={borderColor} />
    <RightTri $fill={fill} $borderColor={borderColor} />
    {borderColor && <RightDiagonalLine $borderColor={borderColor} />}
  </BgRoot>
);

const Content = styled.div<{ $color: string; $imageIcon?: string }>`
  padding: 15px 0;
  position: relative;
  height: 100%;
  color: ${({ $color }) => $color};
  ${({ $imageIcon }) =>
    $imageIcon &&
    css`
      background-size: 24px auto;
      background-image: url(${$imageIcon});
      background-repeat: no-repeat;
      background-position: 12px center;
    `}

  transition: 0.4s;
`;

const RightRect = styled.div<{ $fill?: string; $borderColor?: string }>`
  position: absolute;
  right: -10px;
  top: 0;
  background-color: ${({ $fill }) => $fill};
  width: 10px;
  height: calc(100% - 10px);
  transition: 0.4s;

  ${({ $borderColor }) =>
    $borderColor &&
    css`
      height: calc(100% - 8px);
      top: -1px;
      border-top: solid 1px ${$borderColor};
      border-right: solid 1px ${$borderColor};
    `}
`;
const RightTri = styled.div<{ $fill?: string; $borderColor?: string }>`
  position: absolute;
  right: -10px;
  bottom: 0;
  border-style: solid;
  border-width: ${({ $borderColor }) => ($borderColor ? "9px" : "10px")} 10px 0 0;
  border-color: ${({ $fill }) => $fill} transparent transparent;
  width: 0;
  height: 0;
  transition: 0.4s;
`;
const RightDiagonalLine = styled.div<{ $borderColor: string }>`
  position: absolute;
  right: 0;
  bottom: -1px;
  background-color: ${({ $borderColor }) => $borderColor};
  transform: skewX(-45deg);
  transform-origin: bottom left;
  width: 1px;
  height: 10px;
  transition: 0.4s;
`;

const BgRoot = styled.div<{ $fill: string; $borderColor?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ $fill }) => $fill};
  width: calc(100% - 10px);
  height: 100%;
  z-index: 0;
  transition: 0.4s;

  ${({ $borderColor }) =>
    $borderColor &&
    css`
      border: solid 1px ${$borderColor};
      border-right: none;
    `}
`;

const RootCss = css<{ $fill?: string }>`
  position: relative;
  height: 44px;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (min-width: 961px) {
    min-width: 200px;
  }

  &:hover ${BgRoot}, &:hover ${RightRect} {
    background-color: #1a1a1a;
  }

  &:hover ${RightTri} {
    border-color: #1a1a1a transparent transparent;
  }

  &:hover ${Content} {
    color: #fff;
  }
`;
const ButtonRoot = styled.button<{ $fill?: string }>`
  ${RootCss};
`;
const LinkRoot = styled.a<{ $fill?: string }>`
  ${RootCss};
`;
