import { FrontMemberService, PostQuery } from "../../../lib/service/client/FrontMemberService";
import { boolean, object, string } from "yup";
import { Field, FieldProps, Form, Formik } from "formik";
import { BirthdayField } from "../../common/BirthdayField";
import { FrontRadioButton } from "components/common/FrontRadioButton";
import React, { FC, ReactNode, useState, useRef } from "react";
import { RegisterButton2 } from "../Form/RegisterButton2";
import Link from "next/link";
import { useRouter } from "next/router";
import { AuthErrorMessage } from "../../common/AuthErrorMessage";
import { ValidationErrorV2 } from "../../../lib/service/BaseClientService";
import { FrontFormErrorArea } from "../../common/FrontFormErrorArea";
import { FormikHelpers } from "formik/dist/types";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

const FrontMemberRegisterForm: FC = () => {
  const router = useRouter();
  const [isAuthError, setIsAuthError] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<ValidationErrorV2>({});
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const [recaptchaTouched, setRecaptchaTouched] = useState<boolean>(false);
  const [recaptchaError, setRecaptchaError] = useState<boolean>(false);
  const [authErrorMessage, setAuthErrorMessage] = useState<string | undefined>(undefined);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const onChangeRecaptcha = async (value: string | null) => {
    setRecaptchaValue(value);
    if (value) {
      const response = await fetch(`${process.env.API_BASE_URL}/recaptcha/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ recaptcha_token: value })
      });
      const data = await response.json();
      if (!data.success) {
        setRecaptchaError(true);
      }
    }
  };
  const register = async (query: PostQuery, helpers: FormikHelpers<PostQuery>): Promise<void> => {
    if (recaptchaError) {
      setIsAuthError(true);
      recaptchaRef.current?.reset();
      setAuthErrorMessage('認証に失敗しました。もう一度チェックしてください');
      return;
    }
    if (!recaptchaValue) {
      setRecaptchaTouched(true);
      return;
    }
    const setError = (validationError: ValidationErrorV2): void => {
      helpers.setErrors(validationError);
      setValidationError(validationError);
    };
    const onSuccess = async () => {
      await router.replace(`/login/announce`);
      window.scrollTo(0, 0);
    };
    const memberService = new FrontMemberService();
    await memberService.register(query, router, onSuccess, setIsAuthError, setError);
    helpers.setSubmitting(false);
  };
  return (
    <Formik<PostQuery>
      initialValues={{
        email: "",
        nickname: "",
        password: "",
        gender: "unanswered",
        birthday: "1990/01/01",
        terms_of_service: false,
      }}
      validationSchema={object().shape({
        email: string().email("メールアドレスの形式にしてください").max(100, "100文字以下にしてください").required("メールアドレスは必須項目です"),
        nickname: string().min(2, "2文字以上にしてください").max(25, "25文字以下にしてください").required("ニックネームは必須項目です"),
        password: string().matches(/^[a-zA-Z0-9!-~]+$/, "半角英数記号で入力してください").min(8, "8文字以上にしてください").max(50, "50文字以下にしてください").required("パスワードは必須項目です"),
        terms_of_service: boolean().oneOf([true], "同意してください").required("同意してください"),
      })}
      onSubmit={register}
    >
      {({ isSubmitting }): ReactNode => (
        <Form>
          <FrontFormErrorArea validationError={validationError} />
          <AuthErrorMessage isAuthError={isAuthError} message={authErrorMessage} />
          <div className="login_content_register_form">
            <div className="form form--register">
              <Field component={RegisterInputField} name="email" type="email" autoComplete="email" placeholder="メールアドレス" label="メールアドレス" />
              <Field component={RegisterInputField} name="nickname" type="text" placeholder="ニックネーム" label="ニックネーム" maxLength={25} />
              <Field component={RegisterInputField} name="password" type="password" placeholder="パスワードを入力" label="パスワード" labelnotice="※半角英数字記号8文字以上" />
              <FrontRadioButton label="性別" name="gender" radioNames={GENDERS} />
              <BirthdayField label="生年月日" name="birthday" />
              <Field name="terms_of_service" component={TermAgreementCheck} />
              {process.env.RECAPTCHA_SITE_KEY && (
                <StyledReCAPTCHAContainer>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.RECAPTCHA_SITE_KEY}
                    onChange={onChangeRecaptcha}
                  />
                </StyledReCAPTCHAContainer>
              )}
              {recaptchaTouched && !recaptchaValue && <ErrorMessageBox>{"上記にチェックしてください"}</ErrorMessageBox>}
              <StyledRegisterButton2 disabled={isSubmitting} type="submit" text="会員登録する" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default FrontMemberRegisterForm;

const GENDERS = [
  { label: "男", value: "male" },
  { label: "女", value: "female" },
  { label: "未設定", value: "unanswered" },
];

const TermAgreementCheck: FC<FieldProps> = ({ field, form: { touched, errors }, ...props }: FieldProps) => {
  return (
    <div className="form_terms">
      <input id="terms" type="checkbox" {...field} {...props} />
      <label htmlFor="terms">
        <Link href={"/term"}>
          <a target="_blank">利用規約</a>
        </Link>
        に同意する
      </label>
      {touched[field.name] && errors[field.name] && <p className="form_template_area_block--error">{errors[field.name]}</p>}
    </div>
  );
};

const RegisterInputField: FC<
  FieldProps & {
    label: string;
    labelnotice?: string;
  }
> = ({ field, form: { touched, errors }, ...props }) => (
  <div className="form--item">
    <p className="form--item_name form--item_password">
      {props.label}
      {props.labelnotice && <span>{props.labelnotice}</span>}
    </p>
    <div className="form--item_input form--item_input--black">
      <div className="form--item_input_bg" />
      <input {...field} {...props} />
    </div>
    {touched[field.name] && errors[field.name] && <p className="form_template_area_block--error">{errors[field.name]}</p>}
  </div>
);

const StyledRegisterButton2 = styled(RegisterButton2)`
  @media screen and (max-width: 960px) {
    margin-top: 20px;
  }
  @media screen and (min-width: 961px) {
    margin-top: 10px;
  }
`;
const StyledReCAPTCHAContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;
const ErrorMessageBox = styled.p`
  width: 100%;
  display: inline-block;
  font-size: 12px;
  color: #ff0000;
  text-align: center;
`;
