import React, { FC, HTMLAttributes } from "react";
import SsHeadline from "components/front/layout/SsHeadline";
import { FrontSnsLoginForm } from "components/front/login/FrontSnsLoginForm";
import FrontMemberRegisterForm from "components/front/member/FrontMemberRegisterForm";
import styled from "styled-components";
import ContentSection from "components/front/layout/ContentSection";

export type Props = HTMLAttributes<HTMLElement>;
const RegisterSection: FC<Props> = ({ ...props }) => {
  return (
    <ContentSection {...props} $pcWidth="350px">
      <SsHeadline label="新規会員登録" color="black" />
      <FrontSnsLoginForm />
      <Or>or</Or>
      <FrontMemberRegisterForm />
    </ContentSection>
  );
};
export default RegisterSection;

const Or = styled.p`
  width: 100%;
  text-align: center;
  @media screen and (max-width: 960px) {
    margin-top: 26px;
    margin-bottom: 26px;
    font-size: 18px;
  }
  @media screen and (min-width: 961px) {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 14px;
  }
`;
